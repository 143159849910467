import React, {useEffect, useState} from "react";
import {MDBSpinner, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import moment from "moment";

const itemlookup = [
    { value: 'process_name', name: '工程名称' },
    { value: 'analysis_product_name', name: '分析商品名' },
    { value: 'item_name', name: '項目名称' },
    { value: 'tolerance_lvl_min', name: "単位 min" },
    { value: 'tolerance_lvl_max', name: "単位 max" },
    { value: 'graph_display', name: "グラフ表示" },
    { value: 'range_settings', name: "範囲設定" },
    { value: 'name', name: "名称" },
    { value: 'unit', name: "単位" },
];

const graphlookup = [
    { value: '1', name: 'はい' },
    { value: '0', name: 'いいえ' },

];

const MeasurementLogs = (props) => {
    const [logs, setLogs] = useState([]);
    const [userList, setUserList] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        loadLogs();
    }, []);
    const loadLogs = async () => {
        try {
            setLoader(true);
            let r = [];
            const querySnapshot = await props.db.collection('measurement_logs').doc(props.cProps.match.params.measurementId).get();
            r = {id: querySnapshot.id, ...querySnapshot.data()}
            let logs = r.logs.filter((match) => match.field_path.includes([`process.${props.cProps.match.params.nestIndex}.items.${props.cProps.match.params.index}.`]));
            let user = await props.db.collection('users').get();
            const userData = user.docs.map(doc => ({...doc.data(), id: doc.id}));
            setUserList(userData);
            setLogs(logs);
             setLoader( false);
        } catch (e) {
            console.log('error -> ', e);
            setLoader( false);
        }
    }

    return (
        <>
            {loader && <div className="d-flex justify-content-center mt-5"><MDBSpinner /></div>}
            {
                !loader && <div><h2>測定データ　変更履歴</h2>
                    <a>名称：{props.cProps.match.params.itemName}</a>
                    {logs &&
                        <MDBTable responsive>
                            <MDBTableHead style={{backgroundColor: '#2E75B6', color:'white'}}>
                                <tr>
                                    <th>日付</th>
                                    <th>ユーザー</th>
                                    <th>フィールド</th>
                                    {/*<th>フィールドパス</th>*/}
                                    <th>変更前</th>
                                    <th>変更後</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {logs.map((item, item_index) => (
                                    <tr key={item_index}>
                                        <td>{ moment(item.update_date.toDate()).format('YYYY/MM/DD HH:mm:ss') }</td>
                                        <td>{userList.filter(item1 => item1.id === item.updated_user).map((item2) => {
                                            return item2['last_name'].trim() + ' ' +  item2['first_name'].trim();
                                        })}</td>
                                        <td>{itemlookup.filter(item1 => item1.value === item.field_name).map((item2) => {
                                            return item2['name'].trim() ;
                                        })}</td>
                                      {/*  <td>{item.field_path}</td>*/}
                                        <td>{
                                            item.field_name === 'graph_display' ?
                                                graphlookup.filter(item1 => item1.value.toString() === item.before.toString()).map((item2) => {
                                                    return item2['name']
                                                }) : item.before}</td>
                                        <td>{
                                            item.field_name === 'graph_display' ?
                                                graphlookup.filter(item1 => item1.value.toString() === item.after.toString()).map((item2) => {
                                                    return item2['name']
                                                }) : item.after}</td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>}
                </div>
            }

        </>

    )
}

export default MeasurementLogs;
