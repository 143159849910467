import React,{useState, useEffect} from "react";
import {Controller, useFieldArray} from "react-hook-form";
import {
    MDBBtn,
    MDBIcon, MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdbreact";
import DatePicker from "react-datepicker";
const ThirdNest = ({ nestIndex, control, register, processItems,edit,history,measurementId,errors,getValues,setValue,deleteItems}) => {
    const {fields, remove, append} = useFieldArray({
        control,
        name: `process[${nestIndex}].items`
    });
    const [showMenu, setShowMenu] = useState(null);
    const [showMenuIndex, setShowMenuIndex] = useState(null);
    const [tempItem, setTempItem] = useState(null);
    const [tempIndex, setTempIndex] = useState(null);
    const [itemName, setItemName] = useState(null);
    const [processName, setProcessName] = useState(null);
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [showModalTolerance, setShowModalTolerance] = useState(false);
    const [processIndex, setProcessIndex] = useState(null);
    const [lvlMin, setLvlMin] = useState(null);
    const [lvlMax, setLvlMax] = useState(null);
    const [lvlMinEdit, setLvlMinEdit] = useState(null);
    const [lvlMaxEdit, setLvlMaxEdit] = useState(null);
    const [unitEdit, setUnitEdit] = useState(null);
    const [processId, setProcessId] = useState(null);
    const [itemId, setItemId] = useState(null);
    let selectOption = <>
        <option value={'%'}>%</option>
        <option value={'cm'}>cm</option>
        <option value={'pt'}>pt</option>
        <option value={'V'}>V</option>
        <option value={'μm'}>μm</option>
        <option value={'ppm'}>ppm</option>
        <option value={'℃'}>℃</option>
        <option value={'㎡'}>㎡</option>
        <option value={'㎏'}>㎏</option>
        <option value={'g'}>g</option>
        <option value={'cc'}>cc</option>
        <option value={'ml'}>ml</option>
        <option value={'L'}>L</option>
        <option value={'㎥'}>㎥</option>
        <option value={'μs/cm'}>μs/cm</option>
        <option value={'l/min'}>l/min</option>
        <option value={'μ'}>μ</option>
        <option value={'PH'}>PH</option>
        <option value={'回'}>回</option>
        <option value={'個'}>個</option>
        <option value={'mg/㎡'}>mg/㎡</option>
        <option value={'mg/L'}>mg/L</option>
        <option value={'個/c㎥'}>個/c㎥</option>
        <option value={'kpa'}>kpa</option>
        <option value={'㎥/hr'}>㎥/hr</option>
        <option value={'mpa'}>mpa</option>
        <option value={'hr'}>hr</option>
        <option value={'缶'}>缶</option>
        <option value={'ドラム'}>ドラム</option>
        <option value={'mg/C'}>mg/C</option>
        <option value={'kΩ・cm2'}>kΩ・cm2</option>
        <option value={'セット'}>セット</option>
    </>;

    // useEffect(() => {
    //     document.addEventListener("click",handleClickOutside,false);
    // }, []);
    useEffect(() => {
        console.log()
    }, [showMenu,showMenuIndex]);
    const handleChangeEditMin = (e) => {
        setLvlMinEdit(e.target.value);
    }
    const handleChangeEditMax = (e) => {
        setLvlMaxEdit( e.target.value);
    }
    const handleChangeUnit = (e) => {
        setUnitEdit( e.target.value);
    }
    const handleClickOutside = event => {
        // if(event.target.className!='menuButton' && event.target.className!='menuArea' ) {
            console.log('hello out')
            setShowMenu(null);
            setShowMenuIndex(null)
        // }
    };
   const showMenuHandler = (itemId, index) => {
       console.log('itemId',index, itemId);
       /*let obj;
      if (itemId === showMenu && index === showMenuIndex) {
           obj = {
               showMenu: null,
               showMenuIndex: null
           }
       } else {
           obj = {
               showMenu: itemId,
               showMenuIndex: index
           }
       }*/

        setShowMenu(itemId);
        setShowMenuIndex(index)
    }

    const moreToggleRemove = (e) => {
        let confirm = e && e.currentTarget.dataset.confirm;
        if (confirm) {
            remove(tempIndex);
            console.log('processId', processId);
            console.log('itemId', itemId);
            if(processId!==undefined && itemId!==undefined){
                let data = []
                data.processId = processId;
                data.itemId = itemId;
                deleteItems(data);
            }
            //setRemoveItems(prevState => ({...prevState, ...data}));
            //removeItems.push(data);
            //setValue(`process[${processIndex}].items[${tempIndex}].deleted`,true);

        }
        setShowModalRemove(!showModalRemove);
    }
    const moreToggleTolerance = (e) => {
        let confirm = e && e.currentTarget.dataset.confirm;
        if (confirm) {
            //alert('confirm')
            //remove(tempIndex);
        }
        setValue(`process[${processIndex}].items[${tempIndex}].tolerance_lvl_min`,lvlMinEdit*1);
        setValue(`process[${processIndex}].items[${tempIndex}].tolerance_lvl_max`,lvlMaxEdit*1);
        setValue(`process[${processIndex}].items[${tempIndex}].unit`,unitEdit);
        setShowModalTolerance( getValues(`process[${processIndex}].items[${tempIndex}].range_settings`) === '上限下限共' && lvlMinEdit*1>lvlMaxEdit*1 ? true : false);
    }
    const navigateToLogs = (id,nestIndex,itemId,index,itemName) => {
        console.log('id', id);
        history.push('/measurement/logs'+'/'+id +'/'+nestIndex + '/'+itemId + '/'+index + '/'+itemName);
    }
    return (
        <div>
            {showMenu !== null && showMenuIndex !== null && <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}} onClick={handleClickOutside}/>}
            <MDBModal isOpen={showModalRemove} toggle={moreToggleRemove}>
                <MDBModalHeader toggle={moreToggleRemove}><a style={{color:'#212529',fontWeight:'700'}}>項目を削除</a></MDBModalHeader>
                <MDBModalBody style={{color:'#212529',fontWeight:'500',padding: 30}}>
                    以下の項目を削除します。よろしいですか？
                    <br/>
                    工程{processIndex * 1 + 1 }
                    <br/>
                    工程名称: <span style={{borderWidth: 0,backgroundColor:'white',color:'#212529',fontWeight:'500'}}>{processName}</span>
                   {/* <input readOnly={true} disabled={true} style={{borderWidth: 0,backgroundColor:'white',color:'#212529',fontWeight:'500'}} {...register(`process.${nestIndex}.process_name`)}/>*/}
                    <br/>
                    項目名称：{itemName}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn onClick={moreToggleRemove} color="secondary">キャンセル</MDBBtn>
                    <MDBBtn onClick={moreToggleRemove} color="danger" data-confirm={true}>削除</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
            <MDBModal isOpen={showModalTolerance} toggle={moreToggleTolerance}>
                <MDBModalHeader toggle={moreToggleTolerance}><a style={{color:'#212529',fontWeight:'700'}}>許容範囲変更</a></MDBModalHeader>
                <MDBModalBody style={{color:'#212529',fontWeight:'500',paddingLeft:50,paddingRight:50,paddingTop:25,paddingBottom:25}}>
                    以下の項目の許容範囲を変更します。
                    <br/>
                    <br/>
                    工程{processIndex * 1 + 1 }
                    <br/>
                    工程名称 : <span style={{borderWidth: 0,backgroundColor:'white',color:'#212529',fontWeight:'500'}}>{processName}</span>
                    <br/>
                    項目名称 : {itemName}
                    <br/>
                    <br/>
                    変更前
                    <MDBTable responsive small>
                        <MDBTableHead style={{ backgroundColor: '#2E75B6' }}>
                            <tr style={{color:'#FFFFFF'}}>
                                <th colSpan={2} style={{paddingLeft:'20px'}} >許容範囲</th>
                                <th></th>
                                <th style={{textAlign:'right', paddingRight:'60px'}}>単位</th>
                                <th></th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            <td>
                                <input step="any" type="number" style={{width: '90px',textAlign:'center'}} className="form-control" value={lvlMin} disabled={true}/>
                            </td>
                            <td>
                                <a style={{ fontSize:'20px',marginTop:5, textAlign: 'center', alignItems: 'center',display:"flex",justifyContent:'center'}}>~</a>
                            </td>
                            <td >
                                <div>
                                    <input step="any" type="number" style={{width: '90px',textAlign:'center'}} className="form-control" value={lvlMax} disabled={true}/>
                                </div>

                            </td>
                            <td>
                                <input style={{textAlign:'center',width: '90px', display:'flex',justifyContent:'flex-end'}} className="form-control" {...register(`process[${processIndex}].items[${tempIndex}].unit`)} disabled={true}/>
                            </td>
                        </MDBTableBody>
                    </MDBTable>
                    変更後
                    <MDBTable responsive small>
                        <MDBTableHead style={{ backgroundColor: '#2E75B6' }}>
                            <tr style={{color:'#FFFFFF'}}>
                                <th colSpan={2}  style={{paddingLeft:'20px'}}>許容範囲</th>
                                <th></th>
                                <th style={{textAlign:'right', paddingRight:'60px'}}>単位</th>
                                <th></th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            <td>
                                <input step="any" type="number" style={{width: '90px',textAlign:'center'}} className="form-control" value={lvlMinEdit} onChange={handleChangeEditMin} disabled={getValues(`process[${processIndex}].items[${tempIndex}].range_settings`) ==="上限のみ" ? true : false}/>
                                { getValues(`process[${processIndex}].items[${tempIndex}].range_settings`) === '上限下限共' && lvlMinEdit *1 >lvlMaxEdit *1 && (
                                    <div className="error" style={{color:'red',position:"absolute",zIndex:1000}}>
                                    上限値が下限値を下回っています。
                                    </div>
                                    )}
                            </td>
                            <td>
                                <a style={{ fontSize:'20px',marginTop:5, textAlign: 'center', alignItems: 'center',display:"flex",justifyContent:'center'}}>~</a>
                            </td>
                            <td >
                                <div>
                                    <input step="any" type="number" style={{width: '90px',textAlign:'center'}} className="form-control" value={lvlMaxEdit} onChange={handleChangeEditMax} readOnly={getValues(`process[${processIndex}].items[${tempIndex}].range_settings`) ==="下限のみ" ? true : false}/>
                                </div>

                            </td>
                            <td>
                                <select className="browser-default custom-select" style={{textAlign:'center',width: '90px', display:'flex',justifyContent:'flex-end'}} value={unitEdit} onChange={handleChangeUnit}>
                                    {selectOption}
                                </select>
                            </td>
                        </MDBTableBody>
                    </MDBTable>
                    <MDBTable responsive small>
                        <MDBTableHead style={{ backgroundColor: '#2E75B6' }}>
                            <tr style={{color:'#FFFFFF'}}>
                                <th  style={{paddingLeft:'20px'}}>変更開始年月日</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Controller rules={{ required: true}} control={control} name="start_date" render={({ field: { onChange, onBlur, value, ref } }) => {
                                    return (
                                        <DatePicker
                                            selected={value}
                                            className={'form-control'}
                                            dateFormat="yyyy/MM/dd"
                                            onChange={onChange}
                                        />
                                    )
                                }}>
                                </Controller>
                                </div>
                            </td>
                        </MDBTableBody>
                    </MDBTable>
                </MDBModalBody>
                <MDBModalFooter style={{ display: 'flex', justifyContent: 'center'}}>
                    <MDBBtn type={'button'} onClick={moreToggleTolerance} color="default" data-confirm={true}>確定</MDBBtn>
                </MDBModalFooter>
            </MDBModal>

            <MDBTable responsive>
                <MDBTableHead style={{ backgroundColor: '#f2f2f2' }}>
                <tr>
                    <th>項目名称</th>
                    <th colSpan={2}>許容範囲</th>
                    <th></th>
                    <th>単位</th>
                    <th>範囲設定</th>
                    <th style={{textAlign:'center',alignItems:'center'}}>グラフ表示</th>
                    <th></th>
                </tr>
                </MDBTableHead>
                <MDBTableBody>
                {fields.map((item, k) => (
                    <tr key={item.id} style={{width:'100%'}}>
                        <td style={{width:'40%',paddingLeft:0}}>
                            <input className="form-control" {...register(`process[${nestIndex}].items[${k}].item_name`,{required:true})}/>
                            { errors && errors.process && errors.process[nestIndex] && errors.process[nestIndex].items[k] && errors.process[nestIndex].items[k].item_name && (
                                <div className="error" style={{color:'red'}}>
                                    item name required
                                </div>
                            )}
                        </td>
                        <td style={{width:'10%',paddingLeft:0,paddingRight:3}}>
                            <input step="any" type="number" style={{textAlign:'center'}} className="form-control" readOnly={getValues(`process[${nestIndex}].items[${k}].range_settings`) ==="上限のみ" ? true : false} {...register(`process[${nestIndex}].items[${k}].tolerance_lvl_min`,
                               { required: getValues(`process[${nestIndex}].items[${k}].range_settings`) ==="上限のみ" ? false: true,
                                    validate: () => (getValues(`process[${nestIndex}].items[${k}].range_settings`) ==="上限下限共" ? Number(getValues(`process[${nestIndex}].items[${k}].tolerance_lvl_min`)) <= Number(getValues(`process[${nestIndex}].items[${k}].tolerance_lvl_max`)) : null)},
                            )}/>
                            { errors && errors.process && errors.process[nestIndex] && errors.process[nestIndex].items[k] && errors.process[nestIndex].items[k].tolerance_lvl_min && errors.process[nestIndex].items[k].tolerance_lvl_min.type === "validate" && (
                                <div className="error" style={{color:'red',position:"absolute",zIndex:1000}}>
                                    上限値が下限値を下回っています。
                                </div>
                            )}
                        </td>
                        <td style={{width:'3%',paddingLeft:0,paddingRight:3}}>
                            <a style={{ fontSize:'20px',marginTop:5, textAlign: 'center', alignItems: 'center',display:"flex",justifyContent:'center'}}>~</a>
                        </td>
                        <td style={{width:'10%',paddingLeft:0}}>
                            <div id={`process[${nestIndex}].items[${k}].tolerance_lvl_max`}>
                                <input step="any" type="number" style={{textAlign:'center'}} className="form-control" readOnly={getValues(`process[${nestIndex}].items[${k}].range_settings`) ==="下限のみ" ? true : false} {...register(`process[${nestIndex}].items[${k}].tolerance_lvl_max`,{required: getValues(`process[${nestIndex}].items[${k}].range_settings`) ==="下限のみ" ? false: true,})} id={`process[${nestIndex}].items[${k}].tolerance_lvl_max`} />
                            </div>

                        </td>
                        <td style={{width:'9%',paddingLeft:0}}>
                            {/*<input style={{width: '90px',textAlign:'center'}} className="form-control" {...register(`process[${nestIndex}].items[${k}].unit`)}/>*/}
                            <select className="browser-default custom-select" {...register(`process[${nestIndex}].items[${k}].unit`)} style={{textAlign:'center'}}>
                                {selectOption}
                            </select>
                        </td>
                        <td style={{width:'12%',paddingLeft:0}}>
                            {/*<input style={{width: '90px'}} className="form-control" {...register(`process[${nestIndex}].items[${k}].range_settings`)}/>*/}
                            <select className="browser-default custom-select" {...register(`process[${nestIndex}].items[${k}].range_settings`)} onChange={(e) => {
                                setValue(`process[${nestIndex}].items[${k}].range_settings`,e.target.value,{ shouldValidate: true });
                                e.target.value === '上限のみ' ? setValue(`process[${nestIndex}].items[${k}].tolerance_lvl_min`,"") : (e.target.value === '下限のみ' && setValue(`process[${nestIndex}].items[${k}].tolerance_lvl_max`,""))}}>
                                <option value={'上限下限共'}>上限下限共</option>
                                <option value={'下限のみ'}>下限のみ</option>
                                <option value={'上限のみ'}>上限のみ</option>
                            </select>
                        </td>
                        <td style={{alignItems:'center',textAlign:'center',paddingRight:0,width:'10%'}}>
                           {/* <select className="browser-default custom-select" {...register(`process[${nestIndex}].items[${k}].graph_display`)}>
                                <option value={1}>はい</option>
                                <option value={0}>いいえ</option>
                            </select>*/}
                           <div className="form-check form-check-inline" style={{marginTop:5}}>
                               <input type="checkbox" className="form-check-input" id={`process[${nestIndex}].items[${k}].graph_display`} size='lg' {...register(`process[${nestIndex}].items[${k}].graph_display`)}/>
                               <label className="form-check-label" htmlFor={`process[${nestIndex}].items[${k}].graph_display`}> </label>
                            </div>
                        </td>
                        <td style={{justifyContent: 'flex-end', alignItems: 'center', position: 'absolute'}}>
                            {
                                edit && <div className={'menuButtonView'}>
                                    <div className={'menuButton'} onClick={(e) => {
                                        e.stopPropagation();
                                        showMenuHandler(item.id, k);
                                    }}>
                                        <MDBIcon icon={'ellipsis-v'}/>
                                    </div>
                                    {edit && showMenu === item.id && showMenuIndex === k && <div className={'menuArea'}>
                                        <div onClick={() => {
                                            setProcessName(getValues(`process.${nestIndex}.process_name`));
                                            setItemName(getValues(`process[${nestIndex}].items[${k}].item_name`));
                                            setShowMenu(null);setShowMenuIndex(null);setTempItem(item.id);setTempIndex(k);setProcessIndex(nestIndex);setLvlMin(getValues(`process[${nestIndex}].items[${k}].tolerance_lvl_min`));setLvlMax(getValues(`process[${nestIndex}].items[${k}].tolerance_lvl_max`));
                                            setLvlMinEdit(getValues(`process[${nestIndex}].items[${k}].tolerance_lvl_min`));setLvlMaxEdit(getValues(`process[${nestIndex}].items[${k}].tolerance_lvl_max`));setUnitEdit(getValues(`process[${nestIndex}].items[${k}].unit`));
                                            setShowModalTolerance(true);
                                        }}>
                                            許容範囲を変更
                                        </div>
                                        <div onClick={() => {
                                            setShowMenu(null);setShowMenuIndex(null);
                                            navigateToLogs(measurementId,nestIndex,item.id,k,getValues(`process[${nestIndex}].items[${k}].item_name`))
                                        }}>
                                            許容範囲変更履歴
                                        </div>
                                        <div onClick={() => {
                                            setItemName(getValues(`process[${nestIndex}].items[${k}].item_name`));
                                            setProcessName(getValues(`process.${nestIndex}.process_name`));
                                            setProcessIndex(nestIndex);
                                            setShowMenu(null);setShowMenuIndex(null);setTempItem(item.id);setTempIndex(k);
                                            setItemId(getValues(`process[${nestIndex}].items[${k}].id`));
                                            setProcessId(getValues(`process[${nestIndex}].items[${k}].processId`));
                                            setShowModalRemove(true);
                                        }}>
                                            項目を削除
                                        </div>
                                    </div>}
                                </div>
                            }
                            {
                                !edit && <div type="button" onClick={() => remove(k)} style={{marginTop:5}}><MDBIcon className='ms-1' icon='trash' size='lg' style={{ color: '#888888' }} /></div>
                            }
                        </td>
                    </tr>
                ))}
                </MDBTableBody>
            </MDBTable>
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <MDBBtn size="sm4" style={{ paddingTop: '5px', paddingBottom:'5px', paddingLeft: '60px', paddingRight: '60px'}} className={'text-nowrap'} onClick={() => append(processItems)}>管理項目を追加する</MDBBtn>
            </div>
        </div>
    )
}

export default ThirdNest;
