import React, {useState, useEffect} from "react";
import moment from "moment";
import {MDBBtn, MDBSpinner} from "mdbreact";
import DatePicker from "react-datepicker";
import Measurements from "./Components/Measurements";
import DrawMeasureChart from "./Components/DrawMeasureChart";
import {useFieldArray, useForm} from "react-hook-form";

const PreviewMeasurement = (props) => {
    const [data, setData] = useState({});
    const [title, setTitle] = useState('測定データ');
    const [title1, setTitle1] = useState('現地測定値');
    const [title2, setTitle2] = useState('ラボ測定値');
    const [title3, setTitle3] = useState('自動測定値');
    const [whichIsClicked, setWhichIsClicked] = useState('none');
    const [whichIsClickedPrev, setWhichIsClickedPrev] = useState('none');
    const [insertClicked, setInsertClicked] = useState(false);
    const [insertionDate, setInsertionDate] = useState(new Date());
    const [reloader, setReloader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchChart, setSearChart] = useState(12);
    const [visibleUrl, setVisibleUrl] = useState(false);
    const [reloaderMeasurement, setReloaderMeasurement] = useState(false);
    const [appendItem, setAppendItem] = useState(null);
    const [appendComment, setAppendComment] = useState(null);
    useEffect(() => {
        loadData();
    }, [reloader])

    const loadData = async () => {
        try {
            //console.log('props.cProps.match.params.id', props.cProps.match.params.id);
            let fromOrg = "";
            let toOrg = "";
            if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
                //console.log('logged seller')
                toOrg = props.org.id;
                fromOrg = props.user.organization;
            }
            else if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
                //console.log('logged maker')
                toOrg = props.org.id;
                fromOrg = props.user.organization;
            }
            else if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
                //console.log('props maker')
                toOrg = props.org.id;
                //fromOrg = props.user.organization;
            }
            else if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
                //console.log('logged buyer')
                //fromOrg = props.org.id;
                toOrg = props.user.organization;
            }
            else if (props.orgSeller && props.user && props.user.admin) {
                //console.log("props.orgSeller.id", props.orgSeller.id)
                fromOrg = props.orgSeller.id;
            }
            /*else {
                fromOrg = props.user.organization;
            }*/
            setLoading(true);
            const snap = await props.db.collection('measurement').doc(props.cProps.match.params.id).get();
            let tData;
            tData = {id: snap.id, process: [], ...snap.data()};
            let checkOrg = tData.measureOrgs.filter(ele => [fromOrg, toOrg].includes(ele));
            if (checkOrg && checkOrg.length > 0) {
                setVisibleUrl(true);
            }
            setData(tData);

            let process = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').get();
            let pData = [];
            if (process.docs.length > 0) {
                for (let i = 0; i < process.docs.length; i++) {
                    let doc = process.docs[i];
                    let data = doc.data();
                    data.id = doc.id;
                    data.items = []
                    pData.push(data)
                }
            }
            pData.sort((d1, d2) => {
                return d1.index - d2.index
            });

            if (pData && pData.length > 0) {
                for (let i = 0; i < pData.length; i++) {
                    let items = await props.db.collection('measurement').doc(props.cProps.match.params.id).collection('process').doc(pData[i].id).collection('items').get();
                    let itemData = [];
                    if (items.docs.length > 0) {
                        for (let i1 = 0; i1 < items.docs.length; i1++) {
                            let doc = items.docs[i1];
                            let data1 = doc.data();
                            data1.id = doc.id;
                            data1.processId = pData[i].id;
                            itemData.push(data1)
                        }
                    }
                    itemData.sort((d1, d2) => {
                        return d1.index - d2.index
                    });
                    if (itemData && itemData.length > 0) {
                        for (let j = 0; j < itemData.length; j++) {
                            if (pData[i].id === itemData[j].processId) {
                                pData[i].items.push(itemData[j])
                            }
                        }
                    }
                }
            }

            tData.process = pData;
            //console.log('tData', tData);
            /*tData.process.map((process) => {
                process.items.map((item) => {
                    if (item.automaticMeasure) {
                        /!*!// ✅ Get Max date
                        let maxDate = new Date(Math.max(...item.automaticMeasure.map(element => {return new Date(moment(element.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));}),),);
                        //console.log('maxDate', maxDate);
                        let item_filter = item.automaticMeasure.filter(i => {
                            return moment(i.insertedDate.toDate()).format('YYYY/MM/DD') >=moment(maxDate).subtract(searchChart,'months').format('YYYY/MM/DD' );
                        });
                        item.automaticMeasure = item_filter;*!/
                        item.automaticMeasure.sort((d1, d2) => {
                            let a1 = new Date(moment(d1.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));
                            let a2 = new Date(moment(d2.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));
                            return a1.getTime() - a2.getTime();
                        })
                    }
                    if (item.fieldMeasure) {
                        /!*!// ✅ Get Max date
                        let maxDate = new Date(Math.max(...item.fieldMeasure.map(element => {return new Date(moment(element.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));}),),);
                        //console.log('maxDate', maxDate);
                        let item_filter = item.fieldMeasure.filter(i => {
                            return moment(i.insertedDate.toDate()).format('YYYY/MM/DD') >=moment(maxDate).subtract(searchChart,'months').format('YYYY/MM/DD' );
                        });
                        item.fieldMeasure = item_filter;*!/
                        item.fieldMeasure.sort((d1, d2) => {
                            let a1 = new Date(moment(d1.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));
                            let a2 = new Date(moment(d2.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));
                            return a1.getTime() - a2.getTime();
                        });
                    }
                    if (item.labMeasure) {
                        /!*!// ✅ Get Max date
                        let maxDate = new Date(Math.max(...item.labMeasure.map(element => {return new Date(moment(element.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));}),),);
                        //console.log('maxDate', maxDate);
                        let item_filter = item.labMeasure.filter(i => {
                            return moment(i.insertedDate.toDate()).format('YYYY/MM/DD') >=moment(maxDate).subtract(searchChart,'months').format('YYYY/MM/DD' );
                        });
                        item.labMeasure = item_filter;*!/
                        item.labMeasure.sort((d1, d2) => {
                            let a1 = new Date(moment(d1.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));
                            let a2 = new Date(moment(d2.insertedDate.toDate()).format('YYYY/MM/DD HH:mm'));
                            return a1.getTime() - a2.getTime();
                        })
                    }
                });
            });*/
            //setData(tData);
            setLoading(false);
            return tData;
        } catch (e) {
            console.log('error -> ', e);
            setLoading(false);
        }
    }

    const {register, setError, handleSubmit, control, formState: {errors}, getValues, setValue} = useForm({
        // defaultValues: {}; you can populate the fields by this attribute
        defaultValues: async () => await loadData(),
    });
    const {fields, append, remove, insert} = useFieldArray({
        control,
        name: "process",
    })

    const selectType = (type) => {
        setWhichIsClicked(type);
        type !== 'preview' && setWhichIsClickedPrev(type);
        switch (type) {
            case 'field':
                setTitle('現地測定値　データ入力');
                break;
            case 'lab':
                setTitle('ラボ測定値　データ入力');
                break;
            case 'automatic':
                setTitle('自動測定値　データ入力');
                break;
            case 'preview':
                setTitle('測定データプレビュー');
                break;
            default:
                setTitle('測定データ');
                break;
        }
        setInsertClicked(type === 'preview' ? false : true);
        window.scrollTo(0, 0);
    }

    /*const goBack = () => {
        history.goBack();
    }*/

    const addValueRows = (type) => {
        setAppendItem({value: '', insertedDate: insertionDate, edit: false});
        setAppendComment({comment: '', insertedDate: insertionDate, edit: false});
        setReloaderMeasurement(!reloaderMeasurement)
    }

    const dateHandler = (date) => {
        setInsertionDate(date);
    }

    const cancelHandler = () => {
        // console.log('click cancel');
        setReloader(!reloader);
        // if (whichIsClicked === 'none') {
        //     props.cProps.history.push("/measurement")
        // } else {
        setInsertClicked(false);
        setWhichIsClicked('none');
        setTitle('測定データ');
        // }

        // window.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }

    const cancelHandlerPreview = () => {
        setInsertClicked(true);
        setWhichIsClicked(whichIsClickedPrev);
        switch (whichIsClickedPrev) {
            case 'field':
                setTitle('現地測定値　データ入力');
                break;
            case 'lab':
                setTitle('ラボ測定値　データ入力');
                break;
            case 'automatic':
                setTitle('自動測定値　データ入力');
                break;
            case 'preview':
                setTitle('測定データプレビュー');
                break;
            default:
                setTitle('測定データ');
                break;
        }
        window.scrollTo(0, 0);
    }


    const submitData = async (data) => {
        try {
            //console.log('d', data);
            const newData = {...data};
            newData[`${whichIsClicked}Update`].user = props.user.id;
            newData[`${whichIsClicked}Update`].last_name = props.user.last_name;
            newData[`${whichIsClicked}Update`].first_name = props.user.first_name;
            newData[`${whichIsClicked}Update`].org = props.user.organization;
            newData[`${whichIsClicked}Update`].org_name = props.userOrg.display_name;
            newData[`${whichIsClicked}Update`].updated_date = new Date();
            //setData(prevState => ({...prevState, ...newData}));
            //console.log('onSubmit', newData);
            if(whichIsClicked === 'field') {
                await props.db.collection('measurement').doc(newData.id).update({fieldUpdate: newData.fieldUpdate});
                for (let i = 0; i < newData.process.length; i++) {
                    await props.db.collection('measurement').doc(newData.id).collection('process').doc(newData.process[i].id).update({fieldComments: newData.process[i].fieldComments});
                    for (let j = 0; j < newData.process[i].items.length; j++) {
                        await props.db.collection('measurement').doc(data.id).collection('process').doc(newData.process[i].id).collection('items').doc(newData.process[i].items[j].id).update({fieldMeasure: newData.process[i].items[j].fieldMeasure});
                    }
                }
            }
            if(whichIsClicked === 'lab') {
                await props.db.collection('measurement').doc(newData.id).update(
                    {
                        labUpdate: newData.labUpdate
                    });
                for (let i = 0; i < newData.process.length; i++) {
                    await props.db.collection('measurement').doc(newData.id).collection('process').doc(newData.process[i].id).update({
                        labComments: newData.process[i].labComments
                    });
                    for (let j = 0; j < newData.process[i].items.length; j++) {
                        await props.db.collection('measurement').doc(data.id).collection('process').doc(newData.process[i].id).collection('items').doc(newData.process[i].items[j].id).update({
                            labMeasure: newData.process[i].items[j].labMeasure
                        });
                    }
                }
            }
            if(whichIsClicked === 'automatic') {
                await props.db.collection('measurement').doc(newData.id).update(
                    {
                        automaticUpdate: newData.automaticUpdate
                    });
                for (let i = 0; i < newData.process.length; i++) {
                    await props.db.collection('measurement').doc(newData.id).collection('process').doc(newData.process[i].id).update({
                        automaticComments: newData.process[i].automaticComments
                    });
                    for (let j = 0; j < newData.process[i].items.length; j++) {
                        await props.db.collection('measurement').doc(data.id).collection('process').doc(newData.process[i].id).collection('items').doc(newData.process[i].items[j].id).update({
                            automaticMeasure: newData.process[i].items[j].automaticMeasure
                        });
                    }
                }
            }
           /* await props.db.collection('measurement').doc(newData.id).update(
                {
                    fieldUpdate: newData.fieldUpdate,
                    automaticUpdate: newData.automaticUpdate,
                    labUpdate: newData.labUpdate
                });
            for (let i = 0; i < newData.process.length; i++) {
                await props.db.collection('measurement').doc(newData.id).collection('process').doc(newData.process[i].id).update({
                    fieldComments: newData.process[i].fieldComments,
                    labComments: newData.process[i].labComments,
                    automaticComments: newData.process[i].automaticComments
                });
                for (let j = 0; j < newData.process[i].items.length; j++) {
                    await props.db.collection('measurement').doc(data.id).collection('process').doc(newData.process[i].id).collection('items').doc(newData.process[i].items[j].id).update({
                        fieldMeasure: newData.process[i].items[j].fieldMeasure,
                        labMeasure: newData.process[i].items[j].labMeasure,
                        automaticMeasure: newData.process[i].items[j].automaticMeasure
                    });
                }
            }*/
            cancelHandler();
            /* await props.db.collection('measurement').doc(data.id).update(data);*/
        } catch (e) {
            console.log('error atta while updating', e);
        }
    }

    const dateFormatter = (date) => {
        const format = 'YYYY/MM/DD HH:mm';
        if (moment(date).isValid()) {
            return moment(date).format(format);
        }
        return moment(date.toDate()).format(format);
    }
    let selectOption = <>
        <option value={12}>直近12か月</option>
        <option value={3}>直近3か月</option>
        <option value={2}>直近2か月</option>
        <option value={1}>直近1か月</option>
        <option value={1000}>すべて</option>
    </>;
    const handleSearchChart = (e) => {
        setSearChart(e.target.value);
        setReloader(!reloader);
    }
    return (
        <div>
            {   data && visibleUrl &&
                <div>
                    <form onSubmit={handleSubmit(submitData)}>
                        <div style={{display: 'flex', flexDirection: 'row',marginBottom:20}}>
                            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: '10px'}}>
                                <h1>{title}</h1>
                                <span>対象事業所：{data.organization_name}</span>
                                <span>登録事業所：{data.fromOrganizationName}</span>
                                <span>開始年月日： {data && data.start_date && moment(data.start_date.toDate()).format('YYYY/MM/DD')}</span>
                                <span>名称 : {data.name} </span>
                                {insertClicked && <div style={{display: 'flex', alignItems: 'center'}}>
                                    追加測定日：<DatePicker id="datePicker" className={'form-control'}
                                                      selected={insertionDate}
                                                      showTimeInput timeInputLabel="Time:" dateFormat="yyyy/MM/dd HH:mm"
                                                      onChange={(date) => dateHandler(date)}/>
                                    <MDBBtn size={'md'} onClick={() => addValueRows(whichIsClicked)}>確定</MDBBtn>
                                </div>}
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: '.2',
                                justifyContent: 'space-between', textAlign: 'end'

                            }}>
                                <div style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    height: 46,
                                    alignItems: "flex-start"
                                }}>
                                    <span style={{textAlign: 'right'}}>共有設定中：</span>
                                    <div style={{}}>
                                        {data && data.measureShare && data.measureShare.length > 0 ? data.measureShare.map((item, index) => (
                                            <div key={index}>{item.name}</div>
                                        )) : ''}
                                    </div>
                                </div>
                                {/*   {data.share_settings_name && <span style={{display: 'flex', flexDirection: 'row',justifyContent:'flex-end',marginRight:-55}}>{data.share_settings_name}: <span><MDBInput label=" " type="checkbox" checked={data.share_settings ? true : false} disabled={true} id={"share_settings"}/> </span></span>}*/}
                                <div>
                                    <MDBBtn hidden={whichIsClicked === 'field' || whichIsClicked === 'preview'}
                                            color={''}
                                            className={'measureBlueBtn'}
                                            onClick={() => selectType('field')}>現地測定値入力</MDBBtn>
                                    <MDBBtn hidden={whichIsClicked === 'lab' || whichIsClicked === 'preview'}
                                            className={'measureRedBtn'} onClick={() => selectType('lab')}
                                            color="danger">ラボ測定値入力</MDBBtn>
                                    <MDBBtn hidden={whichIsClicked === 'automatic' || whichIsClicked === 'preview'}
                                            className={'measureOrangeBtn'} onClick={() => selectType('automatic')}
                                            color="warning">自動測定値入力</MDBBtn>
                                </div>
                            </div>
                        </div>
                        {loading && <div className="d-flex justify-content-center mt-5"><MDBSpinner/></div>}
                        {fields.map((item, process_index) => (
                            <div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row', justifyContent: 'space-between',
                                    padding: '5px 10px',
                                    background: '#545454',
                                    color: '#FFF'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <span
                                        style={{fontWeight: 'bold'}}>工程{process_index + 1} : {item.process_name}</span>
                                        <span>分析商品名：{item.analysis_product_name}</span>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <select key={process_index} className="browser-default custom-select"
                                                value={searchChart} onChange={handleSearchChart} style={{height: 38}}>
                                            {selectOption}
                                        </select>
                                    </div>
                                </div>
                                <DrawMeasureChart nestIndex={process_index} {...{control, register}}
                                                  getValues={getValues}
                                                  setValue={setValue} which={whichIsClicked} searchChart={searchChart}/>
                                <div style={{padding: '20px'}}>
                                    {(['none', 'preview'].includes(whichIsClicked) || whichIsClicked == 'field') &&
                                        <Measurements appendItem={appendItem} appendComment={appendComment}
                                                      reloader={reloaderMeasurement} searchChart={searchChart}
                                                      editClicked={insertClicked} nestIndex={process_index} {...{
                                            control,
                                            register
                                        }} getValues={getValues} setValue={setValue} dateFormatter={dateFormatter}
                                            //handleValueChange={handleValueChange}
                                            //commentChangeHandler={commentChangeHandler}
                                                      process_index={process_index} which="field" title={title1}
                                                      color="#2E75B6"/>}
                                    {(['none', 'preview'].includes(whichIsClicked) || whichIsClicked == 'lab') &&
                                        <Measurements appendItem={appendItem} appendComment={appendComment}
                                                      reloader={reloaderMeasurement} searchChart={searchChart}
                                                      editClicked={insertClicked} dateFormatter={dateFormatter}
                                                      nestIndex={process_index} {...{control, register}}
                                                      getValues={getValues} setValue={setValue}
                                                      dateFormatter={dateFormatter}
                                            //handleValueChange={handleValueChange}
                                            //commentChangeHandler={commentChangeHandler}
                                                      process_index={process_index} which="lab" title={title2}
                                                      color="#B71C1C"/>}
                                    {(['none', 'preview'].includes(whichIsClicked) || whichIsClicked == 'automatic') &&
                                        <Measurements appendItem={appendItem} appendComment={appendComment}
                                                      reloader={reloaderMeasurement} searchChart={searchChart}
                                                      editClicked={insertClicked} dateFormatter={dateFormatter}
                                                      nestIndex={process_index} {...{control, register}}
                                                      getValues={getValues} setValue={setValue}
                                                      dateFormatter={dateFormatter}
                                            //handleValueChange={handleValueChange}
                                            //commentChangeHandler={commentChangeHandler}
                                                      process_index={process_index} which="automatic" title={title3}
                                                      color="#FB8804"/>}
                                </div>
                                {/*  <MDBBtn type="submit">submit</MDBBtn>*/}
                            </div>


                        ))}
                        {insertClicked && <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 20,
                            flexDirection: 'column'
                        }}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <MDBBtn size="sm4" className="text-nowrap custom-button-grey" color={'blue-grey'}
                                        onClick={cancelHandler} style={{width: 200}}>キャンセル</MDBBtn>
                                <MDBBtn style={{width: 200}} onClick={() => selectType('preview')}>プレビュー</MDBBtn>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <MDBBtn type="submit" style={{width: 200}}>登録</MDBBtn>
                            </div>
                        </div>
                        }
                        {!insertClicked && !loading &&
                            <div style={{display: 'flex', justifyContent: 'center', margin: 20}}>
                                <MDBBtn hidden={whichIsClicked === 'preview' ? false : true} size="sm4"
                                        onClick={cancelHandlerPreview} style={{width: 200}}>閉じる</MDBBtn>
                                <MDBBtn hidden={whichIsClicked === 'field' || whichIsClicked === 'preview'}
                                        color={''} className={'measureBlueBtn'} style={{width: 200}}
                                        onClick={() => selectType('field')}>現地測定値入力</MDBBtn>
                                <MDBBtn hidden={whichIsClicked === 'lab' || whichIsClicked === 'preview'}
                                        className={'measureRedBtn'} style={{width: 200}}
                                        onClick={() => selectType('lab')} color="danger">ラボ測定値入力</MDBBtn>
                                <MDBBtn hidden={whichIsClicked === 'preview'} disabled={true}
                                        style={{width: 200}}>管理項目編集</MDBBtn>
                            </div>}
                    </form>
                </div>
            }
        </div>
    )
}

export default PreviewMeasurement;
